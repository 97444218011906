import React from "react"
import "./index.scss"
import mapImage from "../../assets/mapsection.png"
import tollTagsImg from "../../assets/TollTags.jpg"
import fifityOff from "../../assets/fifty-percent-off.png"
import dollarIcon from "../../assets/dollar-icon.png"
import alertsIcon from "../../assets/alerts-icon.png"
import rectangle from "../../assets/Rectangle 17.png"
import group560 from "../../assets/Group560.png"
import question1 from "../../assets/question1.png"
import question2 from "../../assets/question2.png"

/* This should be the same name as the base class in ./index.scss */
const CSS_BASE_CLASS_NAME = "update-vehicle"

const Header = () => {
  return (
    <nav className="tp-nav">
      <a aria-current="page" className="" href="/">
        <div className="tp-logo"></div>
      </a>

      <a
        href="https://www.texpresslanes.com/"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        What are TEXpress Lanes
      </a>
      <a
        href="https://www.texpresslanes.com/maps/texpress-lanes-map"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        Where are TEXpress Lanes
      </a>
      <a
        href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use"
        className="hide-on-mobile"
        target="_blank"
        rel="noreferrer"
      >
        How to Use TEXpress Lanes
      </a>
    </nav>
  )
}

// A section with a background
const SectionWithBg = () => {
  return (
    <section className="section-with-bg">
    <div className="flex-container">
    </div>
    </section>
  )
  
  return (
    <section className="section-with-bg">
      <div className="flex-container contain">
        <div className="left-col">
          <h2 className="tp-header">Update your MyTEXpress profile for</h2>
          <img className="fifty-off" src={fifityOff} alt="Girl in a jacket" />
          <p className="text">
            The information for the following vehicle registered to your
            MyTEXpress profile is invalid. Please update your toll tag and
            license plate to stay eligible for exclusive toll discounts and
            receive 50% off each toll on the LBJ, NTE & NTE 35W TEXpress lanes
            from 05/08 to 06/04.*
          </p>

          <p
            className="text small"
            onClick={e => {
              e.preventDefault()
            }}
          >
            *Promotion valid through Thursday, May 4 at 9:00am CST. Discount
            offer only valid on our LBJ/NTE/NTE 35W TEXpress Lanes starting
            Monday, May 8, 2023 at 7:00am CST to Sunday, June 4, 2023 at 11:59pm
            CST. For full Terms & Conditions, please click&nbsp;
            <a
              href="https://www.texpresslanes.com/lbj-nte-nte-35w-texpress-lanes-update-vehicle-discount-promotion-official-rules-2023"
              target="_blank"
              // The link works through the onClick event. The href is unreliable, it does not work at times
              onClick={e => {
                e.preventDefault()
                window.location = e.target.href
              }}
            >
              here.
            </a>
          </p>

          <p className="text">
            Please note that all fields are required when updating or adding new
            vehicle information.
          </p>
        </div>
      </div>
    </section>
  )
  
}




const TransponderLocation = () => {
  /*
    p
    p
    image with 3 cards
    p
    p

    div w/ links  */
  return (
    <section className="transponder-location">
      <p className="text">Need help finding your toll transponder number?</p>
      <p className="text">
        Here are two ways you can locate your toll transponder number:
      </p>
      <br />
      <img
        src={tollTagsImg}
        className="toll-tags-img"
        alt="ways you can locate your toll transponder number"
      />
      <p className="text">
        Refer to the images above to find the information printed on your toll
        tag transponder (on your vehicles windshield. Your toll transponder number is printed on the side facing the driver in the vehicle.)
      </p>
      <br />
      <p className="text">
        You can also find your toll transponder number by logging into your
        respective toll transponder account:
      </p>
      <div className="links">
        <a href="https://csc.ntta.org/olcsc/" target="_blank">
          Visit My TollTag Account
        </a>{" "}
        <span className="hide-on-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">
          Visit My TxTag Account
        </a>{" "}
        <span className="hide-on-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <a href="https://www.hctra.org/Login" target="_blank">
          Visit My EZ TAG Account
        </a>
      </div>
    </section>
  )
}

const Footer = () => {
  return (
    <footer className="tp-footer">
      <a
        href="https://www.facebook.com/TEXpressLanes"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="fb-icon"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
      </a>
      <span className="regular-font"> | </span>
      <a
        href="https://twitter.com/texpresslanes"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="twitter-icon"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
      </a>
      <span className="regular-font"> | </span>
      <a
        href="https://www.texpresslanes.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <span className="regular-font"> | </span> ©2023 LBJ/NTE/NTE 35W TEXpress
      Lanes <span className="regular-font"> | </span> All Rights Reserved
    </footer>
  )
}

const Benefits = () => {
  /* 
- icon
- title
- text
*/
  return (
    <section className="benefits">
      <section className="benefits-first">
        <div>
          <img className="icon" src={question1} alt="Girl in a jacket" />
                

      
          <img className="icon" src={question2} alt="Girl in a jacket" />
                  </div>
      </section>
    </section>
  )
}

const MapSection = () => {
  return (
    <section className="mapsection">
    <section className="map-section">
    <div>
      <img src={mapImage} alt="North Texas Texpress Lanes System Map"/></div>
    </section>
    </section>
  )
}

const DigiohFormSection = () => {
  return (
    <section className="digioh-form">
      <div id="lightbox-inline-form-3c6d326b-d312-4b42-a1d9-501ae44bd6f5"></div>
    </section>
  )
}
const UpdateVehicle = () => (
  <div className={CSS_BASE_CLASS_NAME}>
    <Header />
    <SectionWithBg />
    <Benefits />
    <MapSection />
    <DigiohFormSection />
    <TransponderLocation />
    <Footer />
  </div>
)

export default UpdateVehicle
